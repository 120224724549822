.adm-selector {
  --color: var(--adm-color-fill-content);
  --checked-color: var(--adm-color-wathet);
  --text-color: var(--adm-color-text);
  --checked-text-color: var(--adm-color-primary);
  --border: none;
  --checked-border: none;
  --border-radius: 2px;
  --padding: 8px 16px;
  --gap: 8px;
  ---gap: var(--gap);
  ---gap-horizontal: var(--gap-horizontal, var(--gap));
  ---gap-vertical: var(--gap-vertical, var(--gap));
  overflow: hidden;
  font-size: var(--adm-font-size-7);
  line-height: 1.4;
}
.adm-selector .adm-space.adm-space {
  --gap: 12px;
}
.adm-selector .adm-grid {
  --gap: var(---gap);
  --gap-horizontal: var(---gap-horizontal);
  --gap-vertical: var(---gap-vertical);
}
.adm-selector-item {
  padding: var(--padding);
  position: relative;
  background-color: var(--color);
  border: var(--border);
  border-radius: var(--border-radius);
  color: var(--text-color);
  opacity: 1;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  vertical-align: top;
}
.adm-selector-item-description {
  font-size: var(--adm-font-size-main);
  color: var(--adm-color-weak);
}
.adm-selector-item-active,
.adm-selector-item-multiple-active {
  color: var(--checked-text-color);
  background-color: var(--checked-color);
  border: var(--checked-border);
}
.adm-selector-item .adm-selector-check-mark-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: solid 8px transparent;
  border-bottom: solid 8px var(--adm-color-primary);
  border-left: solid 10px transparent;
  border-right: solid 10px var(--adm-color-primary);
}
.adm-selector-item .adm-selector-check-mark-wrapper > svg {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  width: 8px;
}
.adm-selector-item-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
